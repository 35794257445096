import { Box, Button, ButtonLink, Buttons, Content, Flex, For, If, IntrinsicProps, Title } from 'react-commons';
import { createComponent } from 'react-commons';
import Image from 'next/image';

import style from './index.module.scss';
import Link from 'next/link';
import GameTileImg from '@/components/GameTileImg';
import { DAILY_GAME_QUERY, getTomorrowsGameSrc } from '@/lib/drupal/models/Games';
import GamepadIcon from '@/components/GamepadIcon';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import getDescription from '@/lib/util/jigsawChallengeDescriptions';
import { useRouter } from 'next/router';
import { getZonedDate } from '@/lib/util/zonedTime';
import { get } from 'http';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

interface DailyGameProps extends IntrinsicProps {
  imgSrc: string
  billboardSrc: string[]
  title: string
  description: string
  href: string
  isPremiumUser: boolean
  slug: string
  isJigsawGame?: boolean
  isChallengeJigsaw?: boolean
  id: string
}

function formatDate (date: Date) {
  return format(date, 'MMMM do yyyy');
}

const padLeft = (str: string) => {
  return str.padStart(2, '0');
};

function getImgUrl (type: 'daily' | 'bonus' | 'challenge') {
  const baseUrl = 'https://content.shockwave.com/content/article19/content/';
  const str = type === 'daily'
    ? ''
    : `-${type}`;

  return `${baseUrl}daily-jigsaw${str}/dailyThumbnails/`;
}

function getImgParts (date: Date, offset: number = 0) {
  date = new Date(date);
  date.setDate(date.getDate() + offset);

  const month = padLeft((date.getMonth() + 1).toString());
  const year = date.getFullYear().toString().slice(2);
  const day = date.getDate().toString();

  return {
    month,
    year,
    day,
  };
}

export function getJigsawThumbnail (date: Date, offset: number, type: 'daily' | 'bonus') {
  const { month, year, day } = getImgParts(date, offset);
  
  return `${getImgUrl(type)}${month}_${year}/${type}${day}.jpg`;
}

export default createComponent<DailyGameProps>('DailyGame', { style }, function DailyGame ({ className }, props) {
  const gameHrefs = {
    'jigsawpuzzles': 'dailyjigsaw',
    'bonusjigsaws': 'bonusjigsaw',
    'jigsawpuzzlechallenge': 'challengejigsaw',
  };

  const playUrl = '/gameplayer/' + (gameHrefs[ props.slug ]);

  const [ todaysDate, setTodaysDate ] = useState('');

  const [ imgSrc, setImgSrc ] = useState(
    props.imgSrc
  );

  useEffect(() => {
    const date = getZonedDate();

    setTodaysDate(
      formatDate(date)
    );

    setImgSrc(
      props.isJigsawGame
        ? getJigsawThumbnail(date, 0, props.slug === 'bonusjigsaws' ? 'bonus' : 'daily')
        : props.imgSrc
    );
  }, [ props ]);

  const router = useRouter();
  const urlTarget = getUrlTarget(playUrl);

  const onImageClick = () => {
    if (urlTarget) {
      window.open(playUrl, urlTarget);
    } else {
      router.push(playUrl);
    }
  };

  return (
    <div className={className}>
      <Flex container gap0>

        {/* Big thumbnail */}
        <Flex>
          <GameTileImg 
            extraLarge
            src={imgSrc} 
            title={props.title}    
            onClick={onImageClick}         
          />
        </Flex>

        <Flex fit>
          <div className='--wide'>
            <Flex wide tall directionColumn>
              <Flex wide>
                <div className='--wide'>
                  <Flex container alignCenter className='Flex--gapButtons'>
                    <Flex>
                      <ButtonLink 
                        small 
                        secondary 
                        href={playUrl} 
                        target={urlTarget}
                      >
                        {
                          props.isJigsawGame
                            ? 'Play Today\'s Puzzle'
                            : 'Play Online'
                        }
                        <GamepadIcon />
                      </ButtonLink>
                    </Flex>
                    <Flex>
                      <p className='DailyGame__Description'>
                        {todaysDate}
                      </p>
                    </Flex>
                  </Flex>
                </div>
              </Flex>

              {/* Preview images */}
              <Flex wide pullTop>
                {
                  If(props.isChallengeJigsaw, () => (
                    <DailyGameChallengeBox />
                  )).Else(() => (
                    <DailyGameUpsellBox
                      slug={props.slug}
                      imgSrc={props.imgSrc}
                      billboardSrc={props.billboardSrc}
                      isJigsawGame={props.isJigsawGame}
                      isPremiumUser={props.isPremiumUser}
                      isBonusGame={props.slug === 'bonusjigsaws'}
                      gameId={props.id} 
                      gameTitle={props.title}                    
                    />
                  )).EndIf()
                }
              </Flex>

            </Flex>
          </div>
        </Flex>

      </Flex>
    </div>
  );
});

interface DailyGameChallengeBox extends IntrinsicProps {

}

const DailyGameChallengeBox = createComponent<DailyGameChallengeBox>('DailyGameChallengeBox', {}, function DailyGameChallengeBox ({ className }, props) {
  const today = getZonedDate();
  const description = getDescription(today);

  return (
    <Box className={className}>
      <strong className='Flex Flex--alignCenter'>
        Today's Challenge
      </strong>
      <p>
        <span>{description.challenge}</span> 
      </p>
      <p>
        {description.description}
      </p>
    </Box>
  );
});

interface DailyGameUpsellBoxProps extends IntrinsicProps {
  slug: string
  imgSrc: string
  billboardSrc: string[]
  isJigsawGame: boolean
  isPremiumUser: boolean
  isBonusGame: boolean
  gameId: string
  gameTitle: string
}

export type BonusDay = 'Bonus' | 'Tomorrow' | 'Challenge';
const bonusDays: BonusDay[] = [ 'Bonus', 'Tomorrow', 'Challenge' ];

export function getJigsawBonusUrl (day: BonusDay, isPremiumUser: boolean, isBonusGame: boolean) {
  if (!isPremiumUser) {
    return '/unlimited';
  }
  
  switch (day) {
  case 'Bonus': {
    return '/gamelanding/bonusjigsaws';
  }
  case 'Tomorrow': {
    return getTomorrowsGameSrc(
      '/gameplayer/' +
      (
        isBonusGame
          ? 'bonusjigsaw'
          : 'dailyjigsaw'
      ) +
      '?' + DAILY_GAME_QUERY
    );
  }
  case 'Challenge': {
    return '/gamelanding/jigsawpuzzlechallenge';
  }
  default: {
    return '/';
  }
  }
};

export function getUrl (isBonusGame: boolean, isPremiumUser: boolean, i: number) {
  return (isBonusGame && i == 0)
    ? '/gamelanding/jigsawpuzzles'
    : getJigsawBonusUrl(
      bonusDays[ i ], 
      isPremiumUser,
      isBonusGame
    );
}

export function getUrlTarget (href: string): '_blank' | '_self' | '_parent' | '_top' | undefined {
  return href.includes('gameplayer')
    ? '_blank'
    : undefined;
}

const DailyGameUpsellBox = createComponent<DailyGameUpsellBoxProps>('DailyGameUpsellBox', {}, function DailyGameUpsellBox ({ className }, props) {
  const [ billboards, setBillboards ] = useState<string[]>([
    props.imgSrc,
    props.imgSrc,
    props.imgSrc,
  ]);
  const [ challengeImg, setChallengeImg ] = useState(
    props.imgSrc
  );

  useEffect(() => {
    const date = getZonedDate();

    const gameType = props.isBonusGame
      ? 'bonus'
      : 'daily';

    const oppositeGameType = props.isBonusGame
      ? 'daily'
      : 'bonus';

    const jigsawBillboards = [ 
      getJigsawThumbnail(date, 0, oppositeGameType),
      getJigsawThumbnail(date, 1, gameType), 
      getJigsawThumbnail(date, 0, gameType),
    ];
    
    setBillboards(
      props.isJigsawGame
        ? jigsawBillboards
        : props.billboardSrc
    );
    setChallengeImg(
      props.isJigsawGame
        ? (
          props.isBonusGame
            ? jigsawBillboards[ 0 ]
            : jigsawBillboards[ 2 ]
        )
        : props.imgSrc
    );
  }, [ props ]);

  return (
    <Box className={className}>
      <Flex className='--marSm2__b'>
        <CaptureClickEvent
          action='upsell'
          location='daily_panel_bonus'
          href='/unlimited'
          properties={() => ({
            tag_name: 'upsell_dailyjigsaw_banner',
            id: props.gameId,
            title: props.gameTitle,
            view_node: props.slug,
          })}
        >
          <Link href='/unlimited'>
            <a className='Flex Flex--alignCenter upsell_dailyjigsaw_banner'>
              <img
                src='/images/shockwaveUnlimitedDark.svg'
                alt='Shockwave.com'
                width={100}
                height={34}
                style={{ width: '100px', height: '34px' }}
              />
              {
                If(props.isPremiumUser, () => (
                  <p>
                    You have access to more puzzles with your Shockwave Unlimited Membership!
                  </p>
                ))
                  .Else(() => (
                    <p>
                      Access more puzzles with a Shockwave Unlimited Membership!
                    </p>
                  ))
                  .EndIf()
              }
            </a>
          </Link>
        </CaptureClickEvent>
      </Flex>
      <Flex alignEnd className='DailyGame__Billboards'>
        {
          If(billboards.length && challengeImg, () => (
            <>
              <Flex fit directionColumn alignCenter>
                <CaptureClickEvent
                  action='upsell'
                  location='daily_panel_bonus'
                  href='/unlimited'
                  disabled={props.isPremiumUser}
                  properties={() => ({
                    tag_name: 'upsell_dailyjigsaw_bonus',
                    id: props.gameId,
                    title: props.gameTitle,
                    view_node: props.slug,
                  })}
                >
                  <Link 
                    href={getUrl(props.isBonusGame, props.isPremiumUser, 0)}
                  >
                    <a
                      target={getUrlTarget(getUrl(props.isBonusGame, props.isPremiumUser, 0))}
                      className='upsell_dailyjigsaw_bonus'
                    >
                      <span className='Button Button--small Button--primary DailyGame__BillboardLabel'>
                        {
                          (props.isBonusGame) 
                            ? 'Daily' 
                            : bonusDays[ 0 ]
                        }
                      </span>
                      <Image
                        src={props.billboardSrc[ 0 ]}
                        alt={'Game preview thumbnail'}
                        width={73}
                        height={48}
                        draggable={false}
                        quality={process.env.APP_CONFIG.IMAGE_QUALITY}
                      />
                    </a>
                  </Link>
                </CaptureClickEvent>
              </Flex>
              <Flex fit directionColumn alignCenter>
                <CaptureClickEvent
                  action='upsell'
                  location='daily_panel_bonus'
                  href='/unlimited'
                  disabled={props.isPremiumUser}
                  properties={() => ({
                    tag_name: 'upsell_dailyjigsaw_tomorrow',
                    id: props.gameId,
                    title: props.gameTitle,
                    view_node: props.slug,
                  })}
                >
                  <Link 
                    href={getUrl(props.isBonusGame, props.isPremiumUser, 1)}
                  >
                    <a
                      target={getUrlTarget(getUrl(props.isBonusGame, props.isPremiumUser, 1))}
                      className='upsell_dailyjigsaw_tomorrow'
                    >
                      <span className='Button Button--small Button--primary DailyGame__BillboardLabel'>
                        {
                          bonusDays[ 1 ]
                        }
                      </span>
                      <Image
                        src={props.billboardSrc[ 1 ]}
                        alt={'Game preview thumbnail'}
                        width={73}
                        height={48}
                        draggable={false}
                        quality={process.env.APP_CONFIG.IMAGE_QUALITY}
                      />
                    </a>
                  </Link>
                </CaptureClickEvent>
              </Flex>
              {
                If(props.isPremiumUser, () => (
                  <Flex fit directionColumn alignCenter key={2}>
                    <Link 
                      href={getUrl(props.isBonusGame, props.isPremiumUser, 2)}
                    >
                      <a
                        target={getUrlTarget(getUrl(props.isBonusGame, props.isPremiumUser, 2))}
                      >
                        <span className='Button Button--small Button--primary DailyGame__BillboardLabel'>{bonusDays[ 2 ]}</span>
                        <Image
                          src={challengeImg}
                          alt={'Game preview thumbnail'}
                          width={73}
                          height={48}
                          draggable={false}
                          quality={process.env.APP_CONFIG.IMAGE_QUALITY}
                        />
                      </a>
                    </Link>
                  </Flex>
                ))
                  .Else(() => (
                    <Flex fit>
                      <CaptureClickEvent
                        action='upsell'
                        location='daily_panel_bonus'
                        href='/unlimited'
                        properties={() => ({
                          tag_name: 'upsell_dailyjigsaw_button',
                          id: props.gameId,
                          title: props.gameTitle,
                          view_node: props.slug,
                        })}
                      >
                        <ButtonLink 
                          className='upsell_dailyjigsaw_button' 
                          href='/unlimited' 
                          small secondary fullWidth
                        >
                          Subscribe
                        </ButtonLink>
                      </CaptureClickEvent>
                    </Flex>
                  ))
                  .EndIf()
              }
            </>
          )).EndIf()
        }
      </Flex>
    </Box>
  );
});