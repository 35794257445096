import { createComponent } from 'react-commons';
import style from './index.module.scss';

export default createComponent('GamepadIcon', { style }, function GamepadIcon ({ className }) {
  return (
    <img
      className={className}
      src='/images/gamepadIcon.svg'
      alt='Gamepad Icon'
    />
  );
});
