export default function getDescription (date: Date) {
  let challenge = '';
  let description = '';
  
  const day = date.getDay();
  
  if (day === 0) { // sunday
    challenge = 'Very Easy';
    description = 'All features available.';
  }
  
  else if (day === 1) { // monday
    challenge = 'Very Easy';
    description = 'All features available.';
  }
  
  else if (day === 2) { // tuesday
    challenge = 'Easy';
    description = 'No Preview.';
  }
  
  else if (day === 3) { // wednesday
    challenge = 'Medium';
    description = 'No mass move.';
  }

  else if (day === 4) { // thursday
    challenge = 'Hard';
    description = 'No preview, no mass move, no show edges.';
  }
  
  else if (day === 5) { // friday
    challenge = 'Very Hard';
    description = 'No features, pieces are greyed out.';
  }
  
  else if (day === 6) { // saturday
    challenge = 'Very Easy';
    description = 'All features available.';
  }

  return {
    challenge,
    description,
  };
}
